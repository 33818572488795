import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default function AnnualEmmScienceWorkshop(props) {
  return (
    <Layout lang={props.lang || "en"}>
      <SEO title="Annual EMM Science Workshop" />
      <div
        style={{
          width: "100%",
          height: "calc(100vh + 110px)",
          margin: "auto",
          paddingTop: "110px",
        }}
      >
        <iframe
          src="https://m.quillweb.life/emm-science-workshop-day2/"
          style={{
            border: "none",
            width: "100%",
            height: "100vh",
            margin: "auto",
          }}
        ></iframe>
      </div>
    </Layout>
  )
}
